import type { MetaFunction } from '@remix-run/react';
import { Outlet } from '@remix-run/react';
import * as React from 'react';

export const meta: MetaFunction = () => [
  {
    title: 'dvrt login',
  },
];

export default function AuthenticationPage() {
  return (
    <>
      <div className="container relative grid h-screen select-none flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img
              src="/logo.png"
              alt="company logo"
              className="w-[350px] min-w-[250px]"
              draggable="false"
            />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Divert is a platform that facilitates early cybersecurity
                attack detection and prevention by enticing threats to interact
                with ultra-realistic, artificial, edge and cloud-based deception
                assets, drawing threats away from our customers' real attack
                surface.&rdquo;
              </p>
              <footer className="text-sm">John Appleseed</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
